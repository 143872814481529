import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledTestList, StyledResourceBox, StyledResource, StyledResourceItem } from './ResourceList.style';
import { prop, groupBy, indexBy } from 'ramda';
import { Box } from '@oneboard/ui-components';
import { t } from 'utils/i18n';

export const ResourceList = ({ courseInfo, resource, setResource, width = '332px', pd = '16px' }) => {
  const [resourceMap, setResourceMap] = useState();
  const [seriesMap, setSeriesMap] = useState();

  const init = async () => {
    const { resources, series } = courseInfo;
    if (!resources || resources.length === 0) return;
    const _seriesMap = indexBy(prop('code'), series);
    const _resourceMap = groupBy(prop('seriesCode'))(resources);

    setResourceMap(_resourceMap);
    setSeriesMap(_seriesMap);
  };

  const clickHandler = (res) => {
    setResource(res);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <StyledTestList width={width} padding={pd}>
      <div>
        <StyledResource>
          {resourceMap &&
            seriesMap &&
            Object.keys(resourceMap).map((seriesCode) => {
              return (
                <StyledResourceBox key={seriesCode}>
                  <div className='content'>
                    <div className='list'>
                      {resourceMap[seriesCode]?.map((res) => {
                        return (
                          <StyledResourceItem
                            key={res.bookId}
                            onClick={() => clickHandler(res)}
                            active={resource?.bookId === res.bookId}
                          >
                            {res.name}
                          </StyledResourceItem>
                        );
                      })}
                    </div>
                  </div>
                </StyledResourceBox>
              );
            })}
          {!resourceMap && (
            <Box display='flex' justifyContent='center'>
              {t('containers.resourceList.noMaterials', '尚無可使用的教材測驗')}
            </Box>
          )}
        </StyledResource>
      </div>
    </StyledTestList>
  );
};

ResourceList.propTypes = {
  courseInfo: PropTypes.object,
  width: PropTypes.string,
  pd: PropTypes.string,
};
